<script>
/******************************************************************************
 * Invididual Reading Date Component
 * this component allows toggling between global and individual reading dates
 *****************************************************************************/
import FormRow from "@/components/FormRow/FormRow";
import Calendar from "@/components/Calendar/Calendar";
export default {
    props: {
        meterNumber: {type: String, required: true}
    },
    computed: {
        meterData () {
            return this.$store.getters.meterReadings
                .filter(meter => meter.meterNumber === this.meterNumber)[0];
        },
        initialDate () {
            if (this.meterData && this.meterData.meterReadingDate) {
                return this.meterData.meterReadingDate;
            } else {
                // we do not have the date in the store, so set state accordingly
                const today = new Date().toISOString();
                this.$store.dispatch("SET_INDIVIDUAL_READING_DATE", {
                    meterNumber: this.meterNumber,
                    meterReadingDate: today
                });
                return today;
            }
        },
        formattedDate () {
            const d = new Date(this.initialDate);
            return (d.getDate() < 10 ? "0" + d.getDate() : d.getDate())
                + "." + (d.getMonth() + 1) + "." + d.getFullYear();
        },
        isDateChangeable () {
            const readings = this.$store.getters.meterReadings
                .filter(meter => meter.meterNumber === this.meterNumber);
            let containsDismountedItem = false;
            readings.forEach(meter => {
                if (meter.dismounted) containsDismountedItem = true;
            });
            return !containsDismountedItem;
        }
    },
    components: {
        FormRow,
        Calendar
    },
    methods: {
        changeDateType (type) {
            return this.$store.dispatch("SET_DATE_TYPE", type);
        },
        changeInvididualDate (date) {
            return this.$store.dispatch("SET_INDIVIDUAL_READING_DATE", {
                meterNumber: this.meterNumber,
                meterReadingDate: date.toISOString()
            });
        }
    }
}
</script>

<template>
    <form-row
        label="Ablesedatum"
        class="individual-date">
        <template slot="input">
            <calendar
                :initial-date="initialDate"
                :days-min="14"
                :days-max="2"
                v-if="isDateChangeable"
                @change-date="changeInvididualDate($event)" />
            <div
                class="readonly"
                aria-label="Ablesedatum kann nicht geändert werden, da der Zähler bereits ausgebaut ist."
                title="Ablesedatum kann nicht geändert werden, da der Zähler bereits ausgebaut ist."
                v-if="!isDateChangeable">{{ formattedDate }}</div>
        </template>
    </form-row>
</template>

<style lang="scss" scoped>
    $rowHeight: #{map-get($sizes, "base") * 1.4 + 12px + 4px};

    .readonly {
        cursor: not-allowed;

        line-height: $rowHeight;
    }
</style>
