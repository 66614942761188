<script>
/******************************************************************************
 * METER ITEM Component
 *****************************************************************************/
import FormRow from "@/components/FormRow/FormRow";
import MeterReadingConsumption from "./MeterReadingConsumption";
import MeterReadingRequirements from "./MeterReadingRequirements";
import MeterImage from "./MeterImage/MeterImage";
import ImplausibleReason from "./implausibleReason/implausibleReason";
const EVENTS = ["online", "offline", "load"];
export default {
    data: function () {
        return {
            readingValue: this.$store.getters.meterReadings
                .find(
                    meter =>
                        meter.meterNumber === this.meterNumber
                        && meter.meterItemNumber === this.meterItemNumber
                ).meterReadingValue || undefined,
            preComma: undefined,
            postComma: undefined,
            showImplausibleReason: false,
            isOnline: window.navigator.onLine || true,
            consumption: -1
        }
    },
    props: {
        meterNumber: {type: String, required: true},
        meterItemNumber: {type: Number, required: true}
    },
    components: { FormRow, MeterReadingConsumption, MeterReadingRequirements, MeterImage, ImplausibleReason },
    beforeMount() {
        const readingValue = this.$store.getters.meterReadings
            .find(
                meter =>
                    meter.meterNumber === this.meterNumber
                    && meter.meterItemNumber === this.meterItemNumber
            ).meterReadingValue;
        if (readingValue) {
            this.preComma = readingValue.toString().split(".")[0];
            this.postComma = readingValue.toString().split(".")[1];
            this.consumption = this.readingValue - this.meterItem.meterReadingValue;
        }
    },
    computed: {
        meterItems () {
            return this.$store.getters
                .getMeteringItemsByMeterNumber(this.meterNumber);
        },
        meterItem () {
            return this.meterItems
                .find(meter => meter.meterItemNumber === this.meterItemNumber);
        },
        showMeterRequirements () {
            return (this.meterItem.meterReadingValue !== null)
            || (this.meterItem.meterValueMin !== 0);
        },
        lastReadingOk () {
            return this.readingValue
                && this.readingValue >= this.meterItem.meterReadingValue;
        },
        readingRangeOk () {
            return this.readingValue
                && this.readingValue >= this.meterItem.meterValueMin
                && this.readingValue <= this.meterItem.meterValueMax;
        },
    },
    methods: {
        updateOnlineStatus: function() {
            if (typeof window.navigator.onLine === "undefined") {
                this.isOnline = true;
            } else {
                this.isOnline = window.navigator.onLine;
            }
        },
        checkShowImplausibleReason () {
            const readingValue = this.$store.getters.meterReadings
                .find(
                    meter =>
                        meter.meterNumber === this.meterNumber
                        && meter.meterItemNumber === this.meterItemNumber
                ).meterReadingValue;
            console.log("check implausible reason", readingValue);
            if (readingValue === undefined || readingValue === "") {
                this.showImplausibleReason = false;
            } else {
                this.showImplausibleReason =
                    readingValue < this.meterItem.meterReadingValue
                    || readingValue < this.meterItem.meterValueMin
                    || readingValue > this.meterItem.meterValueMax;
            }
        },
        inputKeyDown (event) {
            const invalidKeyCodes = [107, 109, 110, 69, 187, 188, 189, 190];
            const deleteKeyCodes = [8, 46];
            if (invalidKeyCodes.includes(event.keyCode)) event.preventDefault(); // prevent non-number chars
            if (deleteKeyCodes.includes(event.keyCode)) return; // make sure backspace and delete are possible
            // if we already have max number of digits, prevent further digits
            const currentLength = (typeof this.readingValue === "undefined")
                ? 0 : this.readingValue.toString().length;
            const maxLength = this.meterItem.preCommaDigits || 7;
            // only prevent keyDown if meterValueMin is 0; if we have real values from meterList these are a way better indicator
            if (this.meterItem.meterValueMin === 0 && currentLength >= maxLength) event.preventDefault();
        },
        inputKeyDownPreComma (event) {
            const invalidKeyCodes = [107, 109, 110, 69, 187, 188, 189, 190];
            const deleteKeyCodes = [8, 46];
            if (invalidKeyCodes.includes(event.keyCode)) event.preventDefault(); // prevent non-number chars
            if (deleteKeyCodes.includes(event.keyCode)) return; // make sure backspace and delete are possible
            // if we already have max number of digits, prevent further digits
            const currentLength = (typeof this.preComma === "undefined")
                ? 0 : this.preComma.toString().length;
            const maxLength = this.meterItem.preCommaDigits || 7;
            if (currentLength >= maxLength) event.preventDefault();
            console.log("precomma");
        },
        inputKeyDownPostComma (event) {
            const invalidKeyCodes = [107, 109, 110, 69, 187, 188, 189, 190];
            const deleteKeyCodes = [8, 46];
            if (invalidKeyCodes.includes(event.keyCode)) event.preventDefault(); // prevent non-number chars
            if (deleteKeyCodes.includes(event.keyCode)) return; // make sure backspace and delete are possible
            // if we already have max number of digits, prevent further digits
            const currentLength = (typeof this.postComma === "undefined")
                ? 0 : this.postComma.toString().length;
            const maxLength = this.meterItem.postCommaDigits || 0;
            if (currentLength >= maxLength) event.preventDefault();
            console.log("postcomma");
        },
        onChange () {
            if (this.readingValue !== undefined && this.readingValue !== "" && this.lastReadingOk && this.readingRangeOk) {
                this.$store.dispatch("RESET_IMPLAUSIBLE_REASON", {
                    meterNumber: this.meterNumber,
                    meterItemNumber: this.meterItemNumber
                });
            }
            this.readingValue = `${this.readingValue}`;
            this.readingValue = this.readingValue.replace(",", "").replace(".", "");
            this.$store.dispatch("SET_READING_VALUE", {
                meterNumber: this.meterNumber,
                meterItemNumber: this.meterItemNumber,
                readingValue: this.readingValue
            });
            this.checkShowImplausibleReason();
            this.consumption = this.readingValue - this.meterItem.meterReadingValue;
        },
        onChangeSplit () {
            console.log("change split: ", this.preComma, this.postComma);
            this.preComma = `${this.preComma}`;
            this.preComma = this.preComma.replace(",", "").replace(".", "");
            this.postComma = `${this.postComma}`;
            this.postComma = this.postComma.replace(",", "").replace(".", "");
            this.$store.dispatch("SET_READING_VALUE", {
                meterNumber: this.meterNumber,
                meterItemNumber: this.meterItemNumber,
                readingValue: parseFloat(`${this.preComma}.${this.postComma}`)
            });
            this.checkShowImplausibleReason();
            this.consumption = parseFloat(`${this.preComma}.${this.postComma}`) - this.meterItem.meterReadingValue;
        }
    },
    mounted () {
        EVENTS.forEach(event => window.addEventListener(event, this.updateOnlineStatus));
    },
    beforeDestroy() {
        EVENTS.forEach(event => window.removeEventListener(event, this.updateOnlineStatus));
    }
}
</script>

<template>
    <div
        v-if="meterItem"
        class="meter-item">
        <div class="meter-item-label">
            <div
                v-if="meterItems.length !== 1 && meterItemNumber"
                class="meta primary">Zählwerk {{ meterItemNumber }}</div>
            <div
                v-if="meterItem.meterItemDescription"
                class="meta">{{ meterItem.meterItemDescription }}</div>
            <div
                v-if="meterItem.obis"
                class="meta">
                <span
                    title="Anhand der OBIS-Kennzahl können Sie das richtige Zählwerk identifizieren"
                    aria-label="Anhand der OBIS-Kennzahl können Sie das richtige Zählwerk identifizieren">OBIS-Kennzahl:</span>
                {{ meterItem.obis }}
            </div>
        </div>
        <form-row
            label="Zählerstand"
            :ref-id="`readingValue${meterItem.meterNumber}-${meterItem.meterItemNumber}`"
            class="reading-row">
            <template
                class="meter-reading"
                slot="input">
                <div class="clearfix">
                    <input
                        v-if="!meterItem.enforcePostComma"
                        type="number"
                        :id="`readingValue${meterItem.meterNumber}-${meterItem.meterItemNumber}`"
                        :name="`readingValue${meterItem.meterNumber}-${meterItem.meterItemNumber}`"
                        class="meter-reading"
                        placeholder="Zählerstand"
                        v-model.trim="readingValue"
                        step="10"
                        @keydown="inputKeyDown($event)"
                        @keyup="onChange()"
                        required>
                    <span v-if="meterItem.enforcePostComma">
                        <input
                            type="number"
                            :id="`readingValue${meterItem.meterNumber}-${meterItem.meterItemNumber}preComma`"
                            :name="`readingValue${meterItem.meterNumber}-${meterItem.meterItemNumber}postComma`"
                            class="meter-reading meter-reading--pre-comma"
                            placeholder="Zählerstand vor dem Komma"
                            v-model.trim="preComma"
                            step="10"
                            @keydown="inputKeyDownPreComma($event)"
                            @keyup="onChangeSplit()">
                        <span class="comma">,</span>
                        <input
                            type="number"
                            :id="`readingValue${meterItem.meterNumber}-${meterItem.meterItemNumber}preComma`"
                            :name="`readingValue${meterItem.meterNumber}-${meterItem.meterItemNumber}postComma`"
                            class="meter-reading meter-reading--post-comma"
                            v-model.trim="postComma"
                            step="10"
                            @keydown="inputKeyDownPostComma($event)"
                            @keyup="onChangeSplit()">
                    </span>
                    <div class="unit">{{ meterItem.meterUnit ? meterItem.meterUnit : "&nbsp;" }}</div>
                </div>
            </template>
            <template slot="text">
                <meter-reading-consumption
                    v-if="consumption > 0"
                    :consumption="consumption"
                    :unit="meterItem.meterUnit"
                    :post-comma="meterItem.postCommaDigits ? parseInt(meterItem.postCommaDigits) : 0"
                    :enforce="meterItem.enforcePostComma" />
                <meter-reading-requirements
                    v-if="showMeterRequirements"
                    :meter-number="meterNumber"
                    :meter-item-number="meterItemNumber" />
            </template>
        </form-row>
        <implausible-reason
            v-if="showImplausibleReason"
            :meter-number="meterNumber"
            :meter-item-number="meterItemNumber" />
        <meter-image
            v-if="$store.getters.allowImageUpload && isOnline"
            :meter-number="meterNumber"
            :meter-item-number="meterItemNumber" />
    </div>
</template>

<style lang="scss" scoped>
    .meter-item {
        border-bottom: map-get($sizes, "border") solid map-get($client, "dividerBg");
        margin: #{map-get($sizes, "base") * 1} 0 #{map-get($sizes, "base") * 0.5} 0;

        &:last-child {
            border-bottom-width: 0;
            margin-bottom: 0;
        }
    }

    .meter-item-label {
        display: flex;
        align-items: center;
        flex-wrap: wrap;

        //font-weight: 400;

        .meta {
            padding: #{map-get($sizes, "base") * 0.35} #{map-get($sizes, "base") * 0.5};
            border: map-get($sizes, "border") solid palette("grey", "infra");
            margin: 0 #{map-get($sizes, "base") * 0.5} #{map-get($sizes, "base") * 0.5} 0;

            background: palette("grey", "astral");
            border-radius: map-get($client, "borderRadius");

            line-height: 1;

            &.primary {
                background: palette("grey", "charcoal");
                color: palette("text", "bright");
            }

            > span {
                font-style: italic;
            }
        }

        @include respond-to("medium") {
            margin-left: 35%;
        }
    }

    input[type=number].meter-reading {
        float: left;

        width: calc(100% - 50px);
        max-width: 100%;
        border-right-width: 0;

        border-top-right-radius: 0;
        border-bottom-right-radius: 0;

        @media all and (min-width: #{map-get($breakpoints, "small") + 50px}) {
            width: #{map-get($breakpoints, "small") - 50px};
        }

        &--pre-comma {
            width: calc(100% - 120px - 10px - 50px);
            border-right-width: #{map-get($sizes, "border")};

            @media all and (min-width: #{map-get($breakpoints, "small") + 50px}) {
                width: #{map-get($breakpoints, "small") - 120px - 10px - 50px};
            }
        }

        &--post-comma {
            width: 120px;
            border-right-width: #{map-get($sizes, "border")};
        }

        &:focus + .unit {
            background-color: darken(map-get($client, "inputBg"), 10%);
            color: palette("grey", "black");
            border-color: darken(map-get($client, "inputBg"), 15%);
        }
    }

    .comma {
        float: left;

        width: 10px;

        font-weight: bold;
        text-align: center;
    }

    .unit {
        float: left;

        width: 50px;
        padding: #{map-get($sizes, "base") * 0.5} 2px;
        border: #{map-get($sizes, "border")} solid map-get($client, "inputBorder");
        border-left-width: 0;

        background-color: map-get($client, "inputBg");
        color: map-get($client, "inputText");
        border-top-right-radius: map-get($client, "borderRadius");
        border-bottom-right-radius: map-get($client, "borderRadius");
        box-shadow:
            inset -2px 2px 0 map-get($client, "appBg"),
            inset -2px -2px 0 map-get($client, "appBg");

        line-height: 1.4;
        text-align: center;

        transition:
            background-color map-get($animation-speeds, "fast") linear,
            border-color map-get($animation-speeds, "fast") linear;
    }
</style>
